/* .jss1{
  width:80%;
} */
.dashboard .card{
  border: 1px solid #3A3A56;
  border-radius: 10px;
  position: relative;
}

.dashboard .card h2{
  font-weight: 700;
}

.dashboard .card h5{
  color: #575757;
}

.dashboard .card button{
  position: absolute;
  top: 0;
  right: 0;
  background: #3A3A56;
  color: #ffffff;
  border: none;
  border-radius: 0px 8px 0px 8px;
  padding: 4px 6px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.dashboard .card button:hover{
  cursor: pointer;
}


#uploadBtn{
  background: transparent;
  border: 2px dashed rgb(199, 199, 199);
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 14px;
  color: #575757;
  display: flex;
  justify-content: center;
  align-items: center;
}
#uploadBtn:hover{
  cursor: pointer;
}


.uploadedFile{
  position: relative !important;
  padding: 8px !important;
}

 .uploadedFile .removeIcon{
  color: red ;
  position: absolute ;
  top: -7px ;
  right: -7px ;
  transition: 0.2s;
}

.uploadedFile .removeIcon:hover{
  cursor: pointer;
  transform: scale(1.19);

}

.uploadFile {
  width: max-content;
  height: auto;
  overflow: hidden;
  border: 2px dashed rgb(199, 199, 199);
  margin-top: 6px;
  padding: 6px 12px;
  margin: auto;
}

.uploadFile img {
  width: 60px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

.uploadFile h6 {
  margin-bottom: 0px;
}


.uploadFile p {
  font-size: 14px;
  color: red;
  cursor: pointer;
  margin-left: 25px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.imgReport{
  width: 120px;
  height: 120px;
  margin:5px;
  border: 1px solid #d4d4d4;
  background: #fff;
  border-radius: 3px;
  text-align: center;
  position: relative
}

.statusCardApprove{
  display: flex;
  padding:15px;
  margin:15px;
  box-shadow: 0px 2px 12px 6px rgb(4 119 6 / 87%);
    border: 3px solid green;
  /* border:1px solid black; */
  /* box-shadow: 0 10px 10px 0 rgba(4, 119, 6, 0.87); */
  transition: 0.3s;
  border-radius: 10px;
  cursor: pointer;
  height:130px;
  width:190px;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.statusCard{
  display: flex;
  padding:15px;
  margin:15px;
  /* border:1px solid black; */
  box-shadow: 0 4px 8px 0 rgba(5, 111, 182, 0.87);
  transition: 0.3s;
  border-radius: 10px;
  cursor: pointer;
  height:130px;
  width:190px;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.statusCard:hover{
  background-color: #4f93ec;
  color:aliceblue;
}

.statusCardApprove:hover{
  background-color: #a3d1a1;
  color:aliceblue;
}

.imgContainer{
  width: 150px;
  height: 150px;
  margin:5px;
  border: 1px solid #d4d4d4;
  background: #fff;
  border-radius: 3px;
  text-align: center;
  position: relative
}

.imgContainer-approved{
  width: 150px;
  height: 150px;
  margin:5px;
  border: 5px solid green;
  background: #fff;
  border-radius: 3px;
  text-align: center;
  position: relative
}

.imgContainer-approved:hover .approve {
  opacity: 1;
  cursor: pointer;
}
.imgContainer-approved:hover .reject {
  opacity: 1;
  cursor: pointer;
}

.imgContainer-rejected{
  width: 150px;
  height: 150px;
  margin:5px;
  border: 5px solid red;
  background: #fff;
  border-radius: 3px;
  text-align: center;
  position: relative
}

.imgContainer-rejected:hover .approve {
  opacity: 1;
  cursor: pointer;
}
.imgContainer-rejected:hover .reject {
  opacity: 1;
  cursor: pointer;
}


.imgContainer:hover .textApproved {
  opacity: 1;
  cursor: pointer;
}
.imgContainer:hover .textUnderProcess {
  opacity: 1;
  cursor: pointer;
}
.imgContainer:hover .textRejected {
  opacity: 1;
  cursor: pointer;
}
.imgContainer:hover .approve {
  opacity: 1;
  cursor: pointer;
}
.imgReport:hover .pushtoReportApprove{
  opacity: 1;
  cursor: pointer;
}
.imgReport:hover .pushtoReportReject{
  opacity: 1;
  cursor: pointer;
}
.imgContainer:hover .underProcess {
  opacity: 1;
  cursor: pointer;
}
.imgContainer:hover .reject {
  opacity: 1;
  cursor: pointer;
}
.imgContainer:hover .img {
  opacity: 0.2;
  cursor: pointer;
}
.imgContainer:hover .deleteIcon {
  opacity: 1;
  cursor: pointer;
}

.deleteIcon {
  position: absolute;
  /* top: 50%; */
  bottom: 0;
  transform: translateY(-50%);
  text-align: center;
  top: 20px;
  z-index: 10;
  left: 0;
  opacity: 1;
  height:20%;
  /* background-color: rgb(236, 53, 53); */
  color:#000;
  font-weight: 500;
  cursor: pointer;
  /* border:1px solid rgb(236, 53, 53); */
  transition: opacity .3s linear;
}
.statusIcon {
  position: absolute;
  /* top: 50%; */
  bottom: 0;
  transform: translateY(-160%) translateX(40%);
  text-align: center;
  top: 20px;
  z-index: 10;
  right: 0;
  opacity: 1;
  height:20%;
  /* background-color: rgb(236, 53, 53); */
  color:#000;
  font-weight: 900;
  font-size:10px;
  cursor: pointer;
  /* border:1px solid rgb(236, 53, 53); */
  transition: opacity .3s linear;
}

.textRejected {
  position: absolute;
  /* top: 50%; */
  bottom: 0;
  transform: translateY(-50%);
  text-align: center;
  left: 0;
  right: 0;
  opacity: 0;
  height:20%;
  background-color: rgb(236, 53, 53);
  color:#000;
  font-weight: 500;
  cursor: pointer;
  border:1px solid rgb(236, 53, 53);
  transition: opacity .3s linear;
}
.textUnderProcess {
  position: absolute;
  /* top: 50%; */
  bottom: 0;
  transform: translateY(-50%);
  text-align: center;
  left: 0;
  right: 0;
  opacity: 0;
  height:20%;
  background-color:rgb(236, 212, 53) ;
  color:#000;
  font-weight: 500;
  cursor: pointer;
  border:1px solid rgb(236, 212, 53);
  transition: opacity .3s linear;
}
.textApproved {
  position: absolute;
  /* top: 50%; */
  bottom: 0;
  transform: translateY(-50%);
  text-align: center;
  left: 0;
  right: 0;
  opacity: 0;
  height:20%;
  background-color: rgba(16, 242, 8, 0.881);
  color:#000;
  font-weight: 500;
  cursor: pointer;
  border:1px solid rgb(16, 242, 8, 0.881);
  transition: opacity .3s linear;
}

.reject{
  position: absolute;
  /* top: 50%; */
  bottom: 0;
  transform: translateY(-50%);
  text-align: center;
  left: 0;
  right: 0;
  opacity: 0;
  height:20%;
  z-index: 30;
  background-color: rgb(236, 53, 53);
  color:#fff;
  cursor: pointer;
  border:1px solid rgb(236, 53, 53);
  transition: opacity .3s linear;
}

.approve{
  position: absolute;
  /* top: 50%; */
  bottom: 0;
  transform: translateY(-160%);
  text-align: center;
  left: 0;
  right: 0;
  opacity: 0;
  height:20%;
  z-index: 30;
  background-color: rgb(13, 116, 6);
  color:#fff;
  cursor: pointer;
  border:1px solid rgb(13, 116, 6);
  transition: opacity .3s linear;
}

.pushtoReportApprove{
    position: absolute;
    /* top: 50%; */
    bottom: 0;
    transform: translateY(-180%);
    text-align: center;
    left: 0;
    right: 0;
    opacity: 0;
    height:22%;
    z-index: 30;
    background-color: rgb(13, 116, 6);
    color:#fff;
    cursor: pointer;
    border:1px solid rgb(13, 116, 6);
    transition: opacity .3s linear;
}
.pushtoReportReject{
  position: absolute;
  /* top: 50%; */
  bottom: 0;
  transform: translateY(-70%);
  text-align: center;
  left: 0;
  right: 0;
  opacity: 0;
  height:22%;
  z-index: 30;
  background-color: rgb(213, 8, 8);
  color:#fff;
  cursor: pointer;
  border:1px solid rgb(213, 8, 8);
  transition: opacity .3s linear;
}

.img {
  transition: opacity .3s linear;
  left:0;
  height:100%;
  width:100%;
  position: absolute;
  opacity: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.css-1qgluif-MuiTypography-root .Mui-selected {
  color: "#FFFFFF !important";
}

/* .css-1qgluif-MuiTypography-root {
  /* color: rgba(0, 0, 0, 0.6); 
} */

